import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';
import { OccurrenceAssessment } from '@/models/StudentAssessment';

export type ListOccurrences = BaseFilters & {
  studentId?: number;
  scheduledLessonId?: Array<number>;
};

export const listOccurrences = async (params: ListOccurrences = {}) => {
  const { data } = await requester().get<JsonApiResponse<OccurrenceAssessment>>(
    'occurrences/',
    { params },
  );

  return data;
};

export const getOccurrence = async (occurrenceId: number) => {
  const { data } = await requester().get<OccurrenceAssessment>(
    `occurrences/${occurrenceId}/`,
  );

  return data;
};
