import requester from '@/axios';
import { HackathonStage } from '@/models/Hackathon';
import { BaseFilters, ListServiceFunc } from '@/models/Service';

export type HackathonStagesFilters = BaseFilters & {
  edition?: number;
  date?: string;
  hackathon?: number;
};

export const listHackathonStages: ListServiceFunc<
  HackathonStage,
  HackathonStagesFilters
> = async params => {
  const { data } = await requester().get(`hackathon-stages/`, {
    params,
  });
  return data;
};
