import { createContext } from 'react';
import User, { UserTypeEnum } from '@/models/User';
import useAuth from '../hook/useAuth';
import { RewardEnum, Reward } from '@/models/Rewards';
import { useQueries, useQuery } from '@tanstack/react-query';
import { isStudent, isSuperAdmin, isUnitAdmin } from '@/functions/auth';
import { ListUsersFilters } from '../services/userServices';
import { rewardValuesQueryKeys, usersQueryKeys } from '../services/querykeys';

export interface AdminContextProps {
  teachers: User[];
  rewardsOptions: Reward[];
}

const AdminContext = createContext<AdminContextProps>({} as AdminContextProps);

//TODO: Remover esse contexto
export function AdminProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();

  const rewardTypes = [RewardEnum.COIN, RewardEnum.GEM];

  const unitAdmin = user && isUnitAdmin(user.userType);

  const superAdmin = user && isSuperAdmin(user.userType);

  const params: ListUsersFilters = {
    userType: [UserTypeEnum.TEACHER],
    unitId: unitAdmin ? user?.unitsIds : undefined,
  };

  const { data: teachers } = useQuery({
    keepPreviousData: true,
    enabled: !!user && (unitAdmin || superAdmin),
    ...usersQueryKeys.list(params),
  });

  const rewardsRequest = useQueries({
    queries: rewardTypes.map(rewardType => ({
      ...rewardValuesQueryKeys.list(rewardType),
      enabled:
        !isStudent(user?.userType) &&
        !!user &&
        user.userType !== UserTypeEnum.PARENT,
      refetchOnWindowFocus: false,
    })),
  });

  const [coin, gem] = rewardsRequest.map(({ data }) => data);

  const rewardsOptions = [...(coin?.results || []), ...(gem?.results || [])];

  return (
    <AdminContext.Provider
      value={{ teachers: teachers?.results ?? [], rewardsOptions }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export default AdminContext;
