import requester from '@/axios';
import Activity, { ActivityMeta } from '@/models/Activity';
import { ActivityCorrectAnswers } from '@/models/ActivityProgress';
import JsonApiResponse from '@/models/JsonApiResponse';
import { ListServiceFunc } from '@/models/Service';

export async function getActivity(activityId: number): Promise<Activity> {
  const { data } = await requester().get<Activity>(`activities/${activityId}/`);
  return data;
}

export interface ActivityFilter {
  pageNumber?: number;
  pageSize?: number;
  lessonId?: number;
  id?: number[];
}
export async function listActivities(params: ActivityFilter = {}) {
  const { data } = await requester().get<JsonApiResponse<Activity>>(
    'activities/',
    {
      params,
    },
  );
  return data;
}

export const listActivitiesMeta: ListServiceFunc<
  ActivityMeta,
  ActivityFilter
> = async params => {
  const { data } = await requester().get('activities/metadata/', {
    params,
  });
  return data;
};

export const getActivityAnswers = async (activityId: number) => {
  const { data: answers } = await requester().get<ActivityCorrectAnswers>(
    `activity-correct-answers/${activityId}/`,
  );
  return answers;
};

export interface UpdateActivityParams {
  activityId: number;
  lessonId: number;
}

type ActivityBank = {
  inBank?: boolean;
  isPublicInBank?: boolean;
};

export interface ActivityInBankParams {
  activityId: number;
  bank: ActivityBank;
}

export const updateActivityInBank = async ({
  activityId,
  bank,
}: ActivityInBankParams) => {
  const { data } = await requester().patch<Activity>(
    `activities/${activityId}/set-in-bank/`,
    bank,
  );
  return data;
};

export const updateActivityFromLesson = async (
  { activityId, lessonId }: UpdateActivityParams,
  activity: Partial<Activity> = {},
) => {
  const { data } = await requester().patch<Activity>(
    `lessons/${lessonId}/activities/${activityId}/`,
    activity,
  );
  return data;
};

export interface UpdateActivityParams {
  activityId: number;
  lessonId: number;
}

export const deleteActivity = async ({
  activityId,
  lessonId,
}: UpdateActivityParams) => {
  const { data } = await requester().delete<Activity>(
    `lessons/${lessonId}/activities/${activityId}/`,
  );
  return data;
};

export interface ActivityRequestParams {
  lessonId: number | string;
  slugCourseName?: string;
  klassId?: number;
}

export const createActivity = async (
  lessonId: number,
  body: Partial<Activity>,
) => {
  const { data: newActivity } = await requester().post<Activity>(
    `lessons/${lessonId}/activities/`,
    body,
  );
  return newActivity;
};

export const pushActivityLesson = async ({
  lessonId,
  activityId,
}: {
  lessonId: number;
  activityId: number;
}) => {
  await requester().post(`lessons/${lessonId}/use-activity/`, {
    activityId,
  });
};

export const removeActivityFromLesson = async ({
  lessonId,
  activityId,
}: {
  lessonId: number;
  activityId: number;
}) => {
  await requester().delete(
    `lessons/${lessonId}/remove-activity/${activityId}/`,
  );
};
