import { useState, createContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BellIcon } from '@heroicons/react/outline';

import { getUserSettings, updateSettings } from '@/functions/userSettings';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ModalUpdates from '@/components/common/modals/ModalUpdates';
import Text from '@/components/common/dataDisplay/Text';

export interface SidebarContextProps {
  isOpen: boolean;
  handleClickExpandedMenu?(): void;
  closeSidebar?(): void;
  UpdateSidebarState: (isOpen: boolean) => void;
  setIsMenuCollapsed: (state: boolean) => void;
  setRefs: {
    [x: string]: (ref: HTMLAnchorElement) => void;
  };
  notificationsPageRef: HTMLElement | null;
}

const SidebarContext = createContext<SidebarContextProps>({
  isOpen: false,
  setIsMenuCollapsed(state) {},
  UpdateSidebarState(isOpen) {},
  setRefs: {},
  notificationsPageRef: null,
});

export function SidebarProvider(props: any) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const userSettings = getUserSettings();
  const sidebarStatus = userSettings?.sidebar;

  const sidebarBooleanStatus = sidebarStatus === 'true';

  const [isOpen, setIsOpen] = useState(sidebarBooleanStatus);

  const UpdateSidebarState = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);

    updateSettings({ updates: { sidebar: String(isOpen) } });
  }, []);

  const [notificationsPageRef, setNotificationsPageRef] =
    useState<HTMLElement | null>(null);

  const setRefs: {
    [key in string]: (ref: HTMLAnchorElement) => void;
  } = {
    sidebarNotifications: setNotificationsPageRef,
  };

  return (
    <SidebarContext.Provider
      value={{
        UpdateSidebarState,
        isOpen,
        setIsMenuCollapsed: setIsOpen,
        setRefs,
        notificationsPageRef,
      }}
    >
      {props.children}
      <ComponentGuard roles={[UserTypeEnum.TEACHER]}>
        <ConditionalRenderer condition={!!notificationsPageRef}>
          <ModalUpdates
            keyword="notificationsPageTeacher"
            target={notificationsPageRef}
            className={{
              base: 'max-w-80 z-50',
            }}
          >
            <div className="bg-gradient-to-t from-primary to-secondary bg-secondary rounded-full text-base-100 flex gap-3.5 items-center px-6 py-3 lg:px-5 lg:py-2.5">
              <BellIcon className="w-5" />
              <Text format="poppins-600" text={t('notifications')} />
            </div>
          </ModalUpdates>
        </ConditionalRenderer>
      </ComponentGuard>
    </SidebarContext.Provider>
  );
}

export default SidebarContext;
