import requester from '@/axios';
import { HEADER_CONTENT as headers } from '@/constants';
import JsonApiResponse from '@/models/JsonApiResponse';
import Resource from '@/models/Resource';
import { BaseFilters } from '@/models/Service';
import { convertToFormData } from '@/utils/convertToFormData';

const baseUrl = 'resources';

export type ListResourceFilters = BaseFilters & {
  lessonId?: number;
  scheduledLessonId?: number;
  id?: number[];
};

export type ResourceFields = Omit<Resource, 'id'>;

export type ResourceParams = {
  resourceId: number;
  body: Partial<ResourceFields>;
};

export async function listResources(params: ListResourceFilters = {}) {
  const { data } = await requester().get<JsonApiResponse<Resource>>(
    `${baseUrl}/`,
    {
      params,
    },
  );

  return data;
}

export async function getResource(resourceId: number) {
  const { data } = await requester().get<Resource>(`${baseUrl}/${resourceId}/`);

  return data;
}

export async function createResource(
  body: Partial<Resource>,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
) {
  const formData = convertToFormData(body, {
    resourceFile: (key, value, formData) => {
      if (!value) {
        formData.delete(key);
      } else {
        formData.set(key, value);
      }
    },
  });

  const { data } = await requester().post<Resource>(`${baseUrl}/`, formData, {
    headers,
    onUploadProgress,
  });

  return data;
}

export async function updateResource(
  resourceId: number,
  body: Partial<Resource>,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
) {
  let updated = {};

  const url = `${baseUrl}/${resourceId}/`;

  const { resourceFile, ...rest } = body;

  if (resourceFile || resourceFile === null) {
    const formData = new FormData();

    formData.set('resourceFile', resourceFile ?? '');

    const { data } = await requester().patch<Resource>(url, formData, {
      headers,
      onUploadProgress,
    });

    updated = data;
  }

  const { data } = await requester().patch<Resource>(url, rest, {
    onUploadProgress,
  });

  return { ...updated, ...data };
}

export async function removeResource(resourceId: number) {
  await requester().delete<Resource>(`${baseUrl}/${resourceId}/`);
}

export async function listLessonResources(
  lessonId: number,
  params: ListResourceFilters = {},
) {
  const { data } = await requester().get<JsonApiResponse<Resource>>(
    `lessons/${lessonId}/${baseUrl}/`,
    {
      params,
    },
  );

  return data;
}

export async function getLessonResource(lessonId: number, resourceId: number) {
  const { data } = await requester().get<JsonApiResponse<Resource>>(
    `lessons/${lessonId}/${baseUrl}/${resourceId}/`,
  );

  return data;
}

export async function createLessonResource(
  lessonId: number,
  body: Partial<Resource>,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
) {
  const formData = convertToFormData(body, {
    resourceFile: (key, value, formData) => {
      if (!value) {
        formData.delete(key);
      } else {
        formData.set(key, value);
      }
    },
  });

  const { data } = await requester().post<Resource>(
    `lessons/${lessonId}/${baseUrl}/`,
    formData,
    {
      headers,
      onUploadProgress,
    },
  );

  return data;
}

export async function updateLessonResource(
  lessonId: number,
  resourceId: number,
  body: Partial<Resource> = {},
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
) {
  let updated = {};

  const url = `lessons/${lessonId}/${baseUrl}/${resourceId}/`;

  const { resourceFile, ...rest } = body;

  if (resourceFile || resourceFile === null) {
    const formData = new FormData();

    formData.set('resourceFile', resourceFile ?? '');

    const { data } = await requester().patch<Resource>(url, formData, {
      headers,
      onUploadProgress,
    });

    updated = data;
  }

  const { data } = await requester().patch<Resource>(url, rest, {
    onUploadProgress,
  });

  return { ...updated, ...data };
}

export async function removeLessonResource(
  lessonId: number,
  resourceId: number,
) {
  await requester().delete<Resource>(
    `lessons/${lessonId}/${baseUrl}/${resourceId}/`,
  );
}
