import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BookOpenIcon } from '@heroicons/react/outline';

import { HiddenComponentType } from '@/models/HiddenSectionSettings';

export type HiddenComponentOption = {
  id: HiddenComponentType;
  icon: ReactNode;
  title: string;
};

export default function useHiddenComponents() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hiddenComponent',
  });

  const components: {
    [key in HiddenComponentType]: HiddenComponentOption;
  } = {
    todayLessons: {
      id: 'todayLessons',
      icon: <BookOpenIcon className="w-5" />,
      title: t('todayLessons'),
    },
    teachingWeek: {
      id: 'teachingWeek',
      icon: <BookOpenIcon className="w-5" />,
      title: t('teachingWeek'),
    },
    yourLessons: {
      id: 'yourLessons',
      icon: <BookOpenIcon className="w-5" />,
      title: t('yourLessons'),
    },
  };

  return {
    components,
  };
}
