import requester from '@/axios';
import Flag from '@/models/Flag';
import { BaseFilters, ListServiceFunc } from '@/models/Service';

export type FlagFilters = BaseFilters & {
  name?: string;
};

export const listFlags: ListServiceFunc<Flag, FlagFilters> = async params => {
  const { data } = await requester().get(`flags/`, {
    params,
  });
  return data;
};
