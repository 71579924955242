import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { Project, ProjectMetadata } from '@/models/Project';
import { BaseFilters, ListServiceFunc } from '@/models/Service';
import { CourseTypeEnum } from '@/models/Course';
import { FlagTypeEnum } from '@/models/Flag';

export type ProjectFilters = BaseFilters & {
  byUpVotes?: 'more' | 'less';
  byViews?: 'more' | 'less';
  courseAbbreviation?: string;
  klass?: number;
  toolsId?: number[];
  user?: number;
  unitId?: number[];
  flagsId?: number[];
  flagsIdNot?: number[];
  flagsType?: FlagTypeEnum[];
  courseType?: CourseTypeEnum[];
  dateAfter?: string;
  dateBefore?: string;
  shunfle?: boolean;
};

export const listProjects: ListServiceFunc<
  Project,
  ProjectFilters
> = async params => {
  const { data } = await requester().get<JsonApiResponse<Project>>(
    `projects/`,
    {
      params,
    },
  );
  return data;
};

export const getProject = async (id: number) => {
  const { data: project } = await requester().get<Project>(`projects/${id}/`);
  return project;
};

export const deleteProject = async (id: number) => {
  await requester().delete(`projects/${id}/`);
};

export type CreateProject = Pick<
  Project,
  | 'projectUrl'
  | 'title'
  | 'description'
  | 'shared'
  | 'equipName'
  | 'teacherMentorId'
> & {
  image?: File;
  imageUrl?: string;
  toolId: number;
  usersIds: number[];
  flagsIds?: number[];
};

export const createProject = async ({ image, ...rest }: CreateProject) => {
  rest.imageUrl = rest.imageUrl ? rest.imageUrl : '';
  const { data: projectWithoutImage } = await requester().post<Project>(
    'projects/',
    rest,
  );
  if (image) {
    const formData = new FormData();
    formData.set('image', image);
    const { data: project } = await requester().patch<Project>(
      `projects/${projectWithoutImage.id}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      },
    );
    return project;
  }
  return projectWithoutImage;
};
export const updateProject = async (
  id: number,
  { image, ...rest }: Partial<CreateProject>,
) => {
  const { data: projectWithoutImage } = await requester().patch<Project>(
    `projects/${id}/`,
    rest,
  );
  if (image) {
    const formData = new FormData();
    formData.set('image', image);
    const { data: project } = await requester().patch<Project>(
      `projects/${id}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      },
    );
    return project;
  }
  return projectWithoutImage;
};

export interface GetProjectDataBody {
  url: string;
  tool: string;
}

export const getProjectData = async (body: GetProjectDataBody) => {
  const { data: projectMetadata } = await requester().post<ProjectMetadata>(
    `get-project-data/by-tool/`,
    body,
  );
  return projectMetadata;
};

export const upvoteProject = async (id: number) => {
  await requester().post(`projects/${id}/add-up-vote/`);
};

export const deleteProjectUpvote = async (id: number) => {
  await requester().post(`projects/${id}/remove-up-vote/`);
};
