import requester from '@/axios';
import Hackathon from '@/models/Hackathon';
import { BaseFilters, ListServiceFunc } from '@/models/Service';

export type HackathonFilters = BaseFilters & {
  edition?: number;
  date?: string;
  lastHackathon?: boolean;
};

export const listHackathons: ListServiceFunc<
  Hackathon,
  HackathonFilters
> = async params => {
  const { data } = await requester().get(`hackathons/`, {
    params,
  });
  return data;
};
