import JsonApiResponse from '@/models/JsonApiResponse';
import {
  InfiniteData,
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

type T<
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<
  JsonApiResponse<Model>,
  TError,
  JsonApiResponse<Model>,
  TQueryKey
>;

type Position = 'start' | 'end';

type AddManualQueryOptions = {
  position: Position;
};

const useManualQuery = <
  Model extends { id: number },
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: T<Model, TError, TQueryKey>,
) => {
  const queryClient = useQueryClient();

  const add = (area: Model, addOptions?: AddManualQueryOptions) => {
    const listParents = queryClient.getQueryData<
      InfiniteData<JsonApiResponse<Model>>
    >(options.queryKey as QueryKey);

    const results = listParents?.pages.at(0)?.results;

    if (addOptions?.position === 'end') results?.push(area);
    else results?.unshift(area);

    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  const update = (area: Partial<Model>, newId?: number) => {
    const listParents = queryClient.getQueryData<
      InfiniteData<JsonApiResponse<Model>>
    >(options.queryKey as QueryKey);
    if (!listParents) return;

    for (let i = 0; i < listParents.pages.length; i++) {
      listParents.pages[i].results = listParents.pages[i].results.map(
        existing =>
          existing.id === area.id
            ? { ...existing, ...area, id: newId ?? existing.id }
            : existing,
      );
    }

    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  const remove = (areaId: number) => {
    const listParents = queryClient.getQueryData<
      InfiniteData<JsonApiResponse<Model>>
    >(options.queryKey as QueryKey);
    if (!listParents) return;
    for (let i = 0; i < listParents.pages.length; i++) {
      if (listParents.pages[i].results.find(a => a.id === areaId)) {
        listParents.pages[i].results = listParents.pages[i].results.filter(
          a => a.id !== areaId,
        );
        break;
      }
    }
    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  const addItem = (area: Model) => {
    const listParents = queryClient.getQueryData<JsonApiResponse<Model>>(
      options.queryKey as QueryKey,
    );

    if (!listParents) return;

    listParents.results.push(area);

    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  const updateItem = (area: Model) => {
    const listParents = queryClient.getQueryData<JsonApiResponse<Model>>(
      options.queryKey as QueryKey,
    );

    if (!listParents) return;

    listParents.results = listParents.results.map(existing =>
      existing.id === area.id ? { ...existing, ...area } : existing,
    );

    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  const removeItem = (areaId: number) => {
    const listParents = queryClient.getQueryData<JsonApiResponse<Model>>(
      options.queryKey as QueryKey,
    );

    if (!listParents) return;

    const find = listParents.results.find(existing => existing.id === areaId);

    if (!find) return;

    const removed = listParents.results.filter(
      existing => existing.id !== areaId,
    );

    queryClient.setQueryData(options.queryKey as QueryKey, removed);
  };

  return { add, update, remove, addItem, updateItem, removeItem };
};

export default useManualQuery;
