import requester from '@/axios';
import { CourseCategoryEnum, CourseTypeEnum } from '@/models/Course';
import JsonApiResponse from '@/models/JsonApiResponse';
import Klass, {
  DaysOfWeekTypeEnum,
  KlassPayload,
  KlassProgress,
  KlassStatusEnum,
  KlassTypeEnum,
} from '@/models/Klass';
import { KlassGroup } from '@/models/KlassGroup';
import { BaseFilters } from '@/models/Service';
import { AxiosResponse } from 'axios';

type TimeFilter = `${number}:${number}`;

export type orderingKlassDate =
  | 'klass_start_date'
  | '-klass_start_date'
  | 'klass_end_date'
  | '-klass_end_date'
  | 'day_time'
  | '-day_time';

export type ListKlassesFilters = BaseFilters & {
  status?: KlassStatusEnum[];
  name?: string;
  teacherId?: number;
  unitId?: number[];
  ordering?: orderingKlassDate;
  studentId?: number;
  courseId?: number[];
  enrollmentStatus?: string[];
  courseType?: Array<CourseTypeEnum>;
  hasAbsenceNotification?: boolean;
  dayOfWeek?: Array<DaysOfWeekTypeEnum>;
  courseCategory?: Array<CourseCategoryEnum>;
  endSoon?: boolean;
  startSoon?: boolean;
  hasActiveLessons?: boolean;
  timeAfter?: TimeFilter;
  timeBefore?: TimeFilter;
  klassStatus?: Array<KlassStatusEnum>;
  klassType?: Array<KlassTypeEnum>;
};

export async function getKlass(klassId: number) {
  const { data } = await requester().get<Klass>(`classes/${klassId}/`);

  return data;
}

export const listKlasses = async (params: ListKlassesFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<Klass>>('classes/', {
    params,
  });

  return data;
};

export async function getKlassProgressById(klassId?: number) {
  const { data } = await requester().get<KlassProgress>(
    `classes/${klassId}/progress/`,
  );
  return data;
}

export const updateKlass = async (
  klassId: number,
  parcialKlass: Partial<Klass>,
) => {
  const { data } = await requester().patch(`classes/${klassId}/`, parcialKlass);
  return data;
};

export const updateKlassStartDate = async (
  klassId: number,
  body: Pick<Klass, 'klassStartDate'>,
) => {
  type Response = AxiosResponse<Pick<Klass, 'klassStartDate'>>;
  const { data: klass } = await requester().patch<UpdateKlassStatus, Response>(
    `classes/${klassId}/klass-start-date/`,
    body,
  );
  return klass;
};

type UpdateKlassStatus = Pick<Klass, 'status'>;

export const updateKlassStatus = async (
  klassId: number,
  body: UpdateKlassStatus,
) => {
  type Response = AxiosResponse<Klass>;
  const { data: klass } = await requester().patch<UpdateKlassStatus, Response>(
    `classes/${klassId}/status/`,
    body,
  );
  return klass;
};

export const createKlass = async (klass: KlassPayload) => {
  const { data } = await requester().post<Klass>(`classes/`, klass);
  return data;
};

export async function getKlassGroup(klassId: number) {
  const { data } = await requester().get<KlassGroup>(
    `classes/${klassId}/group/`,
  );
  return data;
}
